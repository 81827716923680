/* Spinner */
.loader-wrapper {
    overflow: hidden;
    position: fixed;
    top: 10px;
    z-index: 90;
    width: 100%;
    text-align: center;
    display: none;
  
    .loader-box {
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin: 0 auto;
  
      .loader,
      .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
      }
  
      .loader {
        margin: 0;
        font-size: 3.5px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(155,155,155, 0.2);
        border-right: 1.1em solid rgba(155,155,155, 0.2);
        border-bottom: 1.1em solid rgba(155,155,155, 0.2);
        border-left: 1.1em solid #999;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
      }
  
      @-webkit-keyframes load8 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
  
      @keyframes load8 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    } /* loader-box */
  } /* loader-wrapper */
  