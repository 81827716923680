  /* Add Font: bowlby-one-sc-regular - latin */
  @font-face {
    font-family: 'Bowlby One SC';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/bowlby-one-sc-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Bowlby One SC Regular'), local('BowlbyOneSC-Regular'),
         url('./fonts/bowlby-one-sc-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/bowlby-one-sc-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/bowlby-one-sc-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/bowlby-one-sc-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/bowlby-one-sc-v12-latin-regular.svg#BowlbyOneSC') format('svg'); /* Legacy iOS */
  }

/* Body style & background image for Homepage Desktop view */
body.homePage {
    margin: 0;
    padding: 0;
    background-image: url("./images/JakeBalcony-small.jpg");  /* "./images/jake-higher-ground-backstage-topspace.jpg" */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 30%; /* for old pic:  background-position: center */
    background-color: black;
}

/* Body style & background image for Homepage Mobile Vertical view */
@media (max-aspect-ratio: 3/2) {
    body.homePageMobile {
        margin: 0;
        padding: 0;
        background-image: url("./images/JakeBalcony-crop.jpg");  /* "./images/jake-higher-ground-backstage.jpg" */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 120%;  /* 225%; */
        background-position-x: 50%; /* 80% */
        background-position-y: 40%; /* 20% */
        background-color: black;
        /* width: 100%; */
        height: 100%;
    }
    
}

/* Body style & background image for Homepage Mobile Horizontal view */
@media (min-aspect-ratio: 3/2) {
    body.homePageMobile {
        margin: 0;
        padding: 0;
        background-image: url("./images/JakeBalcony-small.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-color: black;
        background-position-y: 10%;
    }
}

body.normal {
    margin: 0;
    padding: 0;
    background-color: #000000;
}

/* NAVBAR HEADER TEXT */

.navbarHeaderText {
    font-family: Avenir;
}

.navbarHeaderTextReduced {
    color: #66AAFF;
    font-Family: "Bowlby One SC";
}

@media (max-width: 350px) {
    .navbarHeaderText {
        font-size: 7.5vw;
    }
    .navbarHeaderTextReduced {
        font-size: 5vw;
    }
    .navbarSubtitleText {
        font-size: 5.833333vw;
    }
}

@media (min-width: 351px) and (max-width: 449px) {
    .navbarHeaderText {
        font-size: 8.5vw;
    }
    .navbarHeaderTextReduced {
        font-size: 5.6666vw;
    }
    .navbarSubtitleText {
        font-size: 6.611111vw;
    }
}

@media (min-width: 450px) and (max-width: 546px) {
    .navbarHeaderText {
        font-size: 6.5vw;
    }
    .navbarHeaderTextReduced {
        font-size: 4.33vw;
    }
    .navbarSubtitleText {
        font-size: 5.055555vw;
    }
}

@media (min-width: 547px) and (max-width: 650px) {
    .navbarHeaderText {
        font-size: 5.85vw;
    }
    .navbarHeaderTextReduced {
        font-size: 3.9vw;
    }
    .navbarSubtitleText {
        font-size: 4.25vw;
    }
}

@media (min-width: 650px) and (max-width: 2000px) {
    .navbarHeaderText {
        font-size: 45px;
    }
    .navbarHeaderTextReduced {
        font-size: 30px;
    }
    .navbarSubtitleText {
        font-size: 33px;
    }
}

/* END NAVBAR HEADER TEXT */

@media screen and (max-width: 531px) {
    .container {
        display: flex;
        flex-flow: column;
    }
    .one {
        order: 2;
    }
    .two {
        order: 1;
    }
}

@media screen and (min-width: 600px) {
    .mobile-break {
        display: none;
    }
}

.navbar-custom {
    background-color: #FFFFFF
}

.navbar a {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
}

.navLinkText {
    font-family: Avenir;
    font-size: 16px;
}

.navLinkTextCollapsableMenu {
    font-family: Avenir;
    font-size: 16px;
}

/* @media (max-width: 768px) {
    .navbar-toggler {
        margin-top: -10px;
    }
} */

@media (min-width: 769px) {
    div.imageContainer>img {
        max-width: 60%;
    }
    .mobile-break {
        display: none;
    }
}

small.footerText {
    font-family: Avenir;
}

@media (min-width: 200px) {
    body {
        padding-top: 25px;
    }
    .navbar {
        margin-bottom: 25px;
    }
}

.figure {
    display: table;
    margin-right: auto;
    margin-left: auto;
}

.figure-caption {
    display: table-caption;
    caption-side: bottom;
    text-align: right;
}

div.mediaBox {
    width: 95%;
    /* height: 95%; */
    padding-left: 8px;
    padding-right: 8px;
    border-style: solid;
    border-width: thin;
    border-color: #000000;
    border-radius: 5px;
}

@media (min-width: 768px) {
    div.mediaBox {
        border-style: hidden;
    }
}

video.customPlayer {
    border-style: hidden;
    border-width: thin;
    border-color: #000000;
    border-radius: 5px;
}

audio.customPlayer {
    border-style: hidden;
    border-width: thin;
    border-color: #FFFFFF;
    border-radius: 5px;
}

@media (min-width: 300px) {
    iframe.album {
        height: 500px;
    }
}

@media (min-width: 350px) {
    iframe.album {
        height: 550px;
    }
}

@media (min-width: 400px) {
    iframe.album {
        height: 600px;
    }
}

@media (min-width: 450px) {
    iframe.album {
        height: 630px;
    }
}

@media (min-width: 500px) {
    iframe.album {
        height: 665px;
    }
}

@media (min-width: 576px) {
    iframe.album {
        height: 675px;
    }
}

@media (min-width: 768px) {
    iframe.album {
        height: 475px;
    }
}

@media (min-width: 992px) {
    iframe.album {
        height: 575px;
    }
}

@media (min-width: 1201px) {
    iframe.album {
        height: 675px;
    }
}

@media (min-width: 1401px) {
    iframe.album {
        height: 675px;
    }
}

body, .form-control {
    font-family: Avenir;
}

label {
    color: #333;
}

.btn-send {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
}

.help-block.with-errors {
    color: #ff5050;
    margin-top: 5px;
}

@media (min-width: 1200px) {
    img.custom {
        height: 225px;
    }
}

/* CENTERS THE NAV BAR */

/* @media (min-width: 768px) {
    .navbar-nav.navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
        z-index: 1;
    }
} */

/* CUSTOM TEXT STYLES */

p.customText {
    font-size: 18px;
    font-family: Avenir;
    color: rgba(225, 226, 221, 1.0);
}
@media (max-width: 400px) {
    p.customText {
        font-size: 16px;
        font-family: Avenir;
        color: rgba(225, 226, 221, 1.0);
    }    
}

p.customTextSmall1 {
    font-size: 17px;
    font-family: Avenir;
    color: rgba(225, 226, 221, 1.0);
}
@media (max-width: 400px) {
    p.customTextSmall1 {
        font-size: 14px;
        font-family: Avenir;
        color: rgba(225, 226, 221, 1.0);
    }    
}

p.customTextTwo {
    /* font-family: 'Raleway'; */
    font-size: 25px;
    font-weight: 400;
    color: rgba(225, 226, 221, 1.0);
}

p.customHeader {
    /* font-family: 'Raleway'; */
    font-size: 45px;
    font-weight: 500;
    color: #66AAFF;
    font-variant-caps: all-small-caps;
    line-height: 1;
}

@media (max-width: 500px) {
    p.customHeader {
    font-size: 35px;
    font-weight: 500;
    }
}

/* SETS PHOTO GALLERY IMAGE MAX HEIGHT */

.react-images__view-image {
    max-height: 100vh !important;
}

.react-datepicker-wrapper {
    width: 256px;
}

.react-datepicker-wrapper input {
    width: 256px;
}

/* header {
    height: 100vh;
    min-height: 400px;
    background-size: cover;
    background-image: url('http://localhost:3000/client/src/images/jake-higher-ground-backstage.jpg');
} */

/* NAVBAR COLLAPSE TOGGLE */

.navbar-dark span.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2366AAFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='red' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 4h24M4 10h24M4 16h24'/%3E%3C/svg%3E"); */
    /* color: blue; */
    /* height: inherit; */
}

/* .navbar-dark .navbar-toggler-icon {
    
} */

.navbar-dark .navbar-toggler {
    /* border-color: blue !important; */
    border-width: 2px;
    border-radius: 15px;
    /* height: 30px; */
    /* width: 40px; */
    /* height: 24px !important; */
}

a {
    color: #87c1fa;
/*     text-decoration: underline;
*/
  }

/* (75% = 3/4 aspect ratio)
.videoWrapper {
    position: relative;
    padding-bottom: 75%;
}
*/

.videoWrapperIframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


 .fadeout {
    position: fixed; 
    left: 0;
    right: 0;
    height: 10px;
    background: -webkit-linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
    ); 
    background-image: -moz-linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    background-image: -o-linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    background-image: linear-gradient( /* This one works for Safari */
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    background-image: -ms-linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
    );
}

 
